<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="program_outcome_definition_id" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program_outcome_definition_text')">
                            <ProgramOutcomeDefinitionSelectbox
                                v-model="formData.program_outcome_definition_id"
                            />
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="program_code" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :validate-error="errors[0]">
                            </program-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="tyyc_basic_field_type" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('file_name')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field_type" code="tyyc_basic_field_types"
                                                 :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number" v-model="formData.rank">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                        name="show_in_diploma_supplement"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                :valueType="'true'"
                                v-model="formData.show_in_diploma_supplement"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import ProgramOutcome from "@/services/ProgramOutcome";
import ProgramOutcomeDefinitionSelectbox from "@/components/interactive-fields/ProgramOutcomeDefinitionSelectbox.vue";

export default {
    components: {
        ProgramOutcomeDefinitionSelectbox,
        ParameterSelectbox,
        ProgramSelectbox,
        StaffAutoComplete,
        StatusSelectbox,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                program_outcome_definition_id:null,
                program_code:null,
                rank: null,
                status: null,
                tyyc_basic_field_type: null,
                show_in_diploma_supplement:null
            },
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                ProgramOutcome.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
